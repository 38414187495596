<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="Single Package Test" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Single Package Test Description"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/setup-single-package-min.jpg')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/UN/setup-single-package-min.jpg"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the single package test is to determine
              whether there is a mass explosion of the contents from accidental
              ignition/ initiation. If so it is a candidate for HD 1.1.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Quantity of packages or articles</td>
                    <td>
                      Determine behavior of a package should ignition occur
                    </td>
                    <td></td>
                    <td>One package or article</td>
                  </tr>
                  <tr>
                    <td>Confining medium and quantity</td>
                    <td>
                      Provide a displaceable confining medium that resists the
                      venting of hot combustion gases
                    </td>
                    <td>
                      <small
                        >Simulate typical transport conditions (package
                        stacking)</small
                      >
                    </td>
                    <td>
                      The preferred method of confinement consists of
                      containers, similar in shape and size to the test package,
                      completely filled with earth or sand and placed as closely
                      as possible around the test package to a minimum thickness
                      of confinement, in every direction of 0.5 m for a package
                      not exceeding 0.15 m^3 and 1.0 m for a package greater
                      than 0.15 m^3. Alternative methods of confinement are to
                      use boxes or bags filled with earth or sand placed around
                      and on top of the package or to use loose sand.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Ignition stimulus (detonator, igniter, or device function)
                    </td>
                    <td>
                      Ensure ignition/ initiation of the substance or article as
                      intended or designed (or equivalent)
                    </td>
                    <td>
                      <small
                        >Most likely incident to be encountered during
                        transport</small
                      >
                    </td>
                    <td>
                      The method of ignition depends on the how the device/
                      substance is intended to function as per guidelines in the
                      UN Manual of Tests and Criteria, Test 6 (a).
                    </td>
                  </tr>
                  <tr>
                    <td>Stimulus placement</td>
                    <td>Assess effect on surrounding materials</td>
                    <td>
                      <small>Assess effect on surrounding materials</small>
                    </td>
                    <td>Ignition/ initiation in the center of the package</td>
                  </tr>
                  <tr>
                    <td>Witness plate</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>3.0 mm thick mild steel</td>
                  </tr>
                  <tr>
                    <td>Blast measurement equipment</td>
                    <td>Evidence of reaction type</td>
                    <td></td>
                    <td>Optional</td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Efficiently determine the behavior of the substance or
                      article or otherwise unambiguously interpret the results
                    </td>
                    <td></td>
                    <td>3 trials</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Crater at the test site</td>
                    <td>Visual post-test inspection</td>
                    <td>Hole or depression in ground</td>
                  </tr>
                  <tr>
                    <td>Damage to the witness plate</td>
                    <td>Visual post-test inspection</td>
                    <td>Dent or perforation of the witness plate</td>
                  </tr>
                  <tr>
                    <td>Measurement of a blast</td>
                    <td>Overpressure probes</td>
                    <td>
                      Record of a shock wave (instantaneous rise in pressure)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Disruption and scattering
                      <span style="color: a800000;">[subjective]</span> of the
                      confining material
                    </td>
                    <td>
                      Observation of dispersion rate and visual post-test
                      inspection
                    </td>
                    <td>
                      Comparing observations against the definition of a mass
                      explosion hazard (one which affects almost the entire load
                      virtually instantaneously
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">
                *Assessments are taken together to determine if a mass explosion
                event (HD 1.1) occurred.
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Mass Explosion (-) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/nogo-singlepackage-min.jpg')"
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/UN/nogo-singlepackage-min.jpg"
              />
            </a>
            <TitleComponent
              class="pt-8"
              title="Mass Explosion (+) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/go-singlepackage-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/go-singlepackage-min.jpg" />
            </a>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88934764?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88934764"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88934760?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88934760"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
          <div class="w-full p-4">
            <TitleComponent
              class="pt-8"
              title="Revision History"
              size="medium"
            />
            <p class="p-light">
              14 Jan 2015: Added specifications to the key parameters table
            </p>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
